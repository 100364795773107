<template>
  <el-drawer
    class="elDrawerDetail"
    :title="comName"
    v-model="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    size="75%"
  >
    <div style="margin-bottom:10px">
      <el-date-picker
        class="rangeDate"
        style="width:200px;position:relative;top:3px;margin-right:10px"
        size="small"
        v-model="value1"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始账期"
        end-placeholder="结束账期"
        @change="changeMonth"
        value-format="YYYYMM"
      >
      </el-date-picker>
      <el-radio-group size="small" @change="changePeriod" v-model="detailPeriod" style="position:relative;top:-4px;margin-right:10px" class="radioButton">
        <el-radio-button label="month">本账期</el-radio-button>
        <el-radio-button label="season">本季度</el-radio-button>  
        <el-radio-button label="year">本年度</el-radio-button>  
      </el-radio-group>
    </div>
    <div class="top_select">
      <div style="display: flex;align-items: center;">
        <span style="margin-right:10px;font-size: 14px">银行名称：</span>
        <el-radio-group size="small" @change="getList" v-model="listQuery.bankName" >
          <el-radio-button  v-for="(item,index) in options" :key="index" :label="item.tableNameBank">{{ item.tableNameBank }}</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button size="small" type="success" plain @click="batchConfirm" :disabled="qrStatus != 2" icon="Checked">确认</el-button>
      </div>
    </div>
    <el-table :data="list" border :height="contentStyleObj" style="width: 100%" stripe v-loading="loading">
      <el-table-column prop="invoiceName" label="收支方向" width="90">
        <template #default="scope">
          <span v-if="scope.row.type == 2">借</span>
          <span v-if="scope.row.type == 1">贷</span>
        </template>
      </el-table-column>
      <el-table-column label="账户名称" min-width="240"> 
        <template #default="scope">
          <span>{{ scope.row.otherName}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceDate" label="交易金额" min-width="90">
        <template #default="scope">
          <span>{{scope.row.amount}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="corpName" label="备注" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.remark}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sumAmount" label="交易时间" min-width="90">
        <template #default="scope">
          <span>{{ $parseTime(scope.row.transDate, "{y}-{m}-{d}")}}</span>
        </template>
         </el-table-column>
      <el-table-column prop="sumTax" label="回单影像" width="130" align="center"> 
        <template #default="scope">
          <el-button type="text" :disabled="!scope.row.pdf" size="small" @click="openDetail(scope.row)">
            <i class="iconfont icon-pdf" style="color: rgb(13, 197, 13);font-size: 16px;margin-right: 4px;"  v-if="scope.row.pdf" ></i>
            <i class="iconfont icon-pdf" style="font-size: 16px;margin-right: 4px;" v-else></i><span>电子回单</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="font-size:13px;margin-top:16px;float: left;">
      汇总笔数：<span style="font-weight:600;margin-right:10px">{{info.count}}</span> 
      收入金额：<span style="font-weight:600;margin-right:10px">{{info.in_sum}}</span>  
      支出金额：<span style="font-weight:600;margin-right:10px">{{info.out_sum}}</span>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>

  </el-drawer>
</template>

<script>
import { bankDetailList,bankNameList } from "@/api/bank"
import { confirmAutomaticBill } from '@/api/automatic'
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      listQuery:{
        startTime:"",
        endTime:"",
        bankId:0,
        limit:20,
        page:1
      },
      value1:[],
      drawer:false,
      info:{},
      total:0,
      detailPeriod:'month',
      kcj:0,
      sum:0,
      ysj:0,
      cjLoading:false,
      cxLoading:false,
      selects:[],
      list:[],
      contentStyleObj:{},
      period:"",
      options:[],
      loading:false,
      qrStatus:0,
    }
  },
  mounted () {
    this.contentStyleObj = this.$getHeight(240)
  },
  methods: {
    init(row,period){
      bankNameList({comId:row.comId}).then(res => {
        if(res.data.msg == 'success'){
          if(res.data.data.list && res.data.data.list.length > 0){
            this.options = res.data.data.list
            this.options.map(v => {
              v.tableNameBank = v.name
            })
          }
          if(this.options.length == 0){
            // this.listQuery.bankName = ""
          }else{
            this.listQuery.bankName = this.options[0].tableNameBank
          }
          this.period = period
          this.qrStatus = row.djqr_bank_status
          this.detailPeriod = 'month'
          this.listQuery.comId = row.comId
          this.listQuery.startTime = period
          this.listQuery.endTime = period
          this.value1=[this.listQuery.startTime,this.listQuery.endTime]
          this.comName = row.name
          this.getList()
          this.drawer = true
        }
      })
    },
    getList(){
      this.loading = true
      bankDetailList(this.listQuery).then(res=>{
        this.loading = false
        if(res.data.msg == 'success'){
          this.list = res.data.data.inInvoiceList ? res.data.data.inInvoiceList : []
          this.total = res.data.data.total
          this.info = res.data.data.statistic
        }
      })
    },
  
    changeMonth(){
      this.listQuery.startTime = this.value1[0]
      this.listQuery.endTime = this.value1[1]
      this.getList()
    },
    changePeriod(){
      let year = this.period.slice(0,4)
      let month = this.period.slice(4,6)
      if(this.detailPeriod == 'month'){
        this.value1 = [year+month,year+month]
      }else if(this.detailPeriod == 'season'){
        if(month < '04'){
          this.value1 = [year+'01',year+'03']
        }else if(month < '07'){
          this.value1 = [year+'04',year+'06']
        }else if(month < '10'){
          this.value1 = [year+'07',year+'09']
        }else if(month > '09'){
          this.value1 = [year+'10',year+'12']
        }
      } else if(this.detailPeriod == 'year'){
        this.value1 = [year+'01',year+'12']
      }
      this.listQuery.startTime = this.value1[0]
      this.listQuery.endTime = this.value1[1]
      this.getList()
    },
    openDetail(row) {
      window.open('https://file.listensoft.net' + row.pdf);
    },
    batchConfirm(){
      confirmAutomaticBill([{
        comId:this.listQuery.comId,
        period:this.period,
        type:'bank'
      }]).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('成功确认');
          this.getList()
          this.$emit('success')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
